import SubHeader from "@/components/SubHeader";
import dvTable from  "@/components/devliumComponent/field-table/dv-table"
import DvModal from "@/components/devliumComponent/dv-modal/dv-modal"
import Button from "@/components/button";
export default {
    name: "email-template",
    components: {
        SubHeader,
        dvTable,
        DvModal,
        "neo-button": Button,
    },
    props: [],
    mixins: [],
    data() {
        return {
            isModalVisible: false,
            folderList: [],
            folderData: {
                folderName: '',
                folderDesc: ''
            },
            tableData: {
                columns: [{"label":"Folder","field":"folderName"}],
                rows:[]
            },
            isLoading: false,
        };
    },
    computed: {
        getTableData(){
            return {}
            // return this.tableData = {...this.tableData, rows:this.folderList}
        },
    },
    methods: {
        showModal() {
            this.isModalVisible = true;
          },
        closeModal() {
            this.isModalVisible = false;
        },
        handleCreateFolder(){
            this.isLoading = true 
            if (this.folderName === "") return
            setTimeout(() => {
                let folder_date = {
                    folderName: this.folderData.folderName,
                    folderDesc: this.folderData.folderDesc,
                    id: Math.round(Math.random() * 1000000000), // for dummy content only
                }
                this.folderList.push(folder_date)
                this.folderData = {
                    folderName: '',
                    folderDesc: ''
                }
                this.isLoading = false
                this.closeModal()
                
            }, 1000)
        },
    },
    watch: {},
};
